import React from 'react'
import { Grid, Typography, Container } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
// import Header1 from '../assets/Header1.jpg'
// import Header2 from '../assets/Header2.jpg'
// import Header3 from '../assets/Header3.jpg' 

const useStyles = makeStyles(theme => ({
    link: {
      textDecoration: 'none',
      fontSize: '28px',
      fontFamily: 'Poppins'
    },
    textfield:{
        margin: '5px',
        backgroundColor: '#e4e4e4',
        color: 'black',
        borderRadius: '20px',
        paddingLeft: '8px',
        width: '80%'
    },
    margin: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
         padding: '200px',
        },    
      },
  }));

function handleClick(e) {
    e.preventDefault();
    alert('Thankyou! Your email has been recieved');
  }

export default function Header() {
  const classes = useStyles(); 
    return (
        <div className= {classes.margin}>
             {/* <Carousel autoPlay 
      transitionTime={1000} 
      interval={8000} infiniteLoop 
      showThumbs={false}  showStatus={false}>
      <div><img src={Header1} alt="" /></div>
      <div><img src={Header2} alt=""/></div>
      <div><img src={Header3} alt=""/></div> 
      </Carousel>     */}
        <Typography className={classes.link} align="center">We're currently developing our full line, sign up for our newsletter to be informed when we've launched</Typography><br/>
       
        <Container maxWidth="sm" align="center">                             
        <form >
        <InputBase className={classes.textfield} inputProps={{ 'aria-label': 'naked' }}      
        endAdornment={
        <InputAdornment position="end"> 
        <ArrowRightIcon fontSize='large' onClick={handleClick} style={{cursor:'pointer',  color: 'black'}}>
        </ArrowRightIcon>
        </InputAdornment>  }  />
        </form> 
        </Container>      
        </div>
    )
}
