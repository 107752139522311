import './App.css';
import React from "react";
import {  BrowserRouter as Router,  Switch,  Route} from "react-router-dom";
import Privacy from './components/Privacy'
import Termsofuse from './components/Termsofuse'
import Contactform from './components/Contactform'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from './components/Appbar'
import  Box   from '@material-ui/core/Box';
import Drawer from './components/Drawer'
import Footer from './components/Footer';
import Home from './components/Home';
import Cutters from './components/Cutters';
import Dies from './components/Dies';
import Swagers from './components/Swagers';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

 function App() {
  return (
    <div>
       <ScrollToTop /> 
       <Navbar/>   
      <Box display={{ md: 'block', lg: 'none' }} ><Drawer/></Box> 

       <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/contactform" component={Contactform}></Route>
        <Route path="/privacypolicy" component={Privacy}></Route>
        <Route path="/termsofuse" component={Termsofuse}></Route>
        <Route path="/cutters" component={Cutters}></Route>
        <Route path="/dies" component={Dies}></Route>
        <Route path="/swagers" component={Swagers}></Route>
      </Switch>
      <Footer/>
    </div>
  );
}


export default App;
