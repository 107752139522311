import React from 'react';
import Backtotop from './Backtotop'
import About from './About';
import Contact from './Contact';
import Wheretobuy from './Wheretobuy';
import Header from './Header';

function Home () {     
  return (
    <div>            
      <Backtotop/>  
      <Header/>
      <Wheretobuy/>              
      <Contact/> 
      <About/>  
   </div>
    );
   }
 export default Home;
