import React from 'react'
import {  Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    pagetitle:{
     color: '#231f20',
     fontFamily:'Poppins',
     paddingTop:'30px',
     fontSize: '42px'
   },
   pagesubtitle:{
    paddingBottom: '40px', 
    fontFamily: 'Poppins',
    fontSize:'20px'
   },
 }));

export default function About() {
    const classes = useStyles();
    return (
        <div id="about" style={{backgroundColor: '#d6d9dd', paddingBottom: '50px'}}>    
            <Container maxWidth="md" component="main" >
                <Typography  align="center" className={classes.pagetitle} gutterBottom>
                About Us
                </Typography>   
                <Typography  className={classes.pagesubtitle}>
                    The American spirit of innovation and quality are what Crenshaw Rigging Tools is all about. Made in USA at the Sea-Land manufacturing facility in New Orleans, these tools are setting a new standard for hand swagers and cutters. Finally, no more guessing about what swager or cavity to use for the various swage ferrules. Each ferrule has a die set engineered to fit into the Crenshaw swager. Interchangeable aluminum handles let users configure the tool to balance convenience and swaging / cutting force. Raising the bar through ingenuity and invention!  
             </Typography>              
             </Container>   
        </div>
    )
}
